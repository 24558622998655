.overview .stats {
    display: grid;
    width: 100%;
    grid-template-columns: max-content auto;
    grid-gap: 5px;
    align-items: center;
    font-size: clamp(12px, 14px + 1vw, 20px);
    column-gap: 5%;
}
.overview .stats .total {
    font-size: 1.6em;
    font-weight: bold;
}
.overview .label {
    border-bottom: 1px solid lightgray;
    color: gray;
    font-size: 0.8em;
}
.overview span {
    padding-right: 10px;
}
.overview .stats .buyValue {
    color: #999;
    font-size: 0.7em;
}
.overview .stats .diffAbs {
    font-size: 0.8em;
}


.todayChart {
    white-space : nowrap;
    box-sizing: border-box;
    display: flex;
    position: relative;
}
.todayChart > div {
    flex-wrap: wrap;
    display: inline-flex;
    height: 25px;
    border: 1px solid #ddd;
    border-right: none;
    align-content: center;
    font-size: clamp(0.6rem, 0.5rem + 0.4vw, 1rem);
    color: #888;
    overflow: "hidden";
}
.todayChart > div:last-of-type {
    border-right: auto;
}    
.todayChart > div:not(.isDateFromToday) {
    background-color: white !important;
    border-color: rgb(247, 247, 247);
    color: white;
}
.todayChart > div:hover {
    filter: opacity(80%);
}

.todayChart::after {
    content: ' ';
    left: calc(var(--zeroPercentLeft) * 1% - 1px);
    height: 30px;
    border-left: 2px solid black;
    position: absolute;
    top: 0;
    top: -2px
}

