/*
 style here: https://www.cssscript.com/ios-segmented-controls/
 code here: https://jsfiddle.net/hf4zrwk6/15/
*/
.switcher {
    width: 60%;
    max-width: 400px;
    padding: 2px;  
    margin: 2px 2px 0 0;
    border-radius: 7px;
    background: #eee;
    position: absolute;
    right: 0px;
    top: 0px;
}

.modal .switcher {
    position: relative;
}

.switcher .inner {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: calc(8px + 1vw);
    position: relative;
    outline: none;
    width: 100%; 
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 7px;
    background: rgb(226, 226, 226);
}

@media screen and (min-width: 1000px) {
    .switcher .inner {
        font-size: 16px;
    }
}

.switcher label {
    display: inline-grid;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    padding: 2px 1px;
    color: #444;
    width: 100%;
    z-index: 10;
    overflow: hidden;
}
.switcher label:not(.active):hover {
    background: rgb(210, 210, 210);
}
.switcher span {
    height: 100%;
    display: block;
    background: white;
    width: calc(100% / var(--count));
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.12), 0 3px 1px 0 rgba(0,0,0,0.04);
    border-radius: 5px;
    position: absolute;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    left: calc(100% / var(--count) * (var(--active) - 1));
    top: 0px;
    z-index: 5;
}

.switcher[data-count="2"] {max-width: 200px;}
.switcher[data-count="3"] {max-width: 300px;}
.switcher[data-count="4"] {max-width: 400px;}
.switcher[data-count="5"] {max-width: 500px;}
.switcher[data-count="6"] {max-width: 600px;}

.switcher label:nth-last-of-type(1):first-child ~ span {--count: 1;}
.switcher label:nth-last-of-type(2):first-child ~ span {--count: 2;}
.switcher label:nth-last-of-type(3):first-child ~ span {--count: 3;}
.switcher label:nth-last-of-type(4):first-child ~ span {--count: 4;}
.switcher label:nth-last-of-type(5):first-child ~ span {--count: 5;}
.switcher label:nth-last-of-type(6):first-child ~ span {--count: 6;}

.switcher label.active:nth-of-type(1) ~ span {--active: 1;}
.switcher label.active:nth-of-type(2) ~ span {--active: 2;}
.switcher label.active:nth-of-type(3) ~ span {--active: 3;}
.switcher label.active:nth-of-type(4) ~ span {--active: 4;}
.switcher label.active:nth-of-type(5) ~ span {--active: 5;}
.switcher label.active:nth-of-type(6) ~ span {--active: 6;}
