.folioSelector_wrapper {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: 350px;
    max-width: 350px;
    flex : 1;
}

.folioSelector {
    width: 100%;
}

.folioSelector .folioSelector__option {
    padding: 6px 8px !important;
    font-size: 0.9em !important;
}

.folioSelector__value-container {
    padding: 4px 8px !important;
}

.folioSelector__value-container,
.folioSelector__single-value {
    font-weight: bold;
}

.folioSelector__control {
    min-height: auto !important;
    max-height: 40px !important;
}

.folioSelector__control--menu-is-open,
.folioSelector__control:hover{
    box-shadow: none;
}

.folioSelector__indicator {
    padding: 0 4px !important;
}

.folioSelector__indicator-separator {
    margin: 4px 0 !important;
}

.folioSelector__menu {
    margin-top: 2px !important;
}

.folioSelector .folioSelector__option:not(:last-child) {
    margin-bottom: 2px;
}
@media screen and (max-width: 767px) {
    .folioSelector .folioSelector__option:not(:last-child) {
        margin-bottom: 10px;
    }
}


.folioSelector_wrapper .edit {
    font-size: 1.3em;
    font-weight: normal;
    margin-left: 5px;
    cursor: pointer;
    color: #555;
}
.folioSelector_wrapper .edit:hover {
    color: black;
}


.folioSelector.singlePeerfolio .folioSelector__menu-list:after {
    content: "Sign up to create more peerfolios & connect to friends' ones";
    color: gray;
    padding: 10px 10px;
    /* margin: 10px 10px; */
    border-top: 1px solid lightgray;
    /* width: 100%; */
    display: block;
    font-size: 80%;
}