.App > header {
    height: 45px;
    box-shadow: 0 -1px 12px hsla(174, 63%, 15%, 0.15);
}

.navbar {
    background: white;
    box-shadow: 0 -1px 12px hsla(174, 63%, 15%, 0.15);
}
.navbar-brand {
    font-size: 1.5em;
    color: var(--title-color);
    font-weight: 600;
}
.nav-link {
    padding: 3px 0 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    row-gap: 4px;
    color: var(--title-color) !important;
}
.nav-icon {
    font-size: 1.5rem;
    font-weight: 100;
}
.nav-link.active {
    color: var(--first-color) !important;
}
.nav-link:hover {
    color: var(--first-color);
}

/* MOBILE **************************************************************** */
@media screen and (max-width: 767px) {
    main {
        bottom: 65px;
    }

    .navbar-nav {
        height: 65px;
        position: fixed;
        bottom: 0;
        left: 0;
        position: fixed;
        background-color: var(--container-color);
        box-shadow: 0 -1px 12px hsla(174, 63%, 15%, 0.15);
        width: 100%;
        display: flex;
        align-content: center;
        flex: 0 0 auto;
        justify-content: space-between;
    }
    .nav-item {
        width: 100%;
        padding: 2px;
    }
    .nav-item a:hover {
        background: #ddd;
    }
    .nav-item a {
        font-size: x-small;
        font-weight: bold;
        flex-direction: column;
        padding: 10px;
    }
    .nav-link.active {
        background: #eee;
    }
}

/* SMALL MOBILE ************************************************ */
@media screen and (max-width: 320px) {
    .nav-name {
        display: none;
    }
}
