/**
 * ToDo: Nicer Tabs
 * https://codepen.io/alvarotrigo/pen/VwMJgGO (design)
 * https://codepen.io/alvarotrigo/pen/KKXjJBm (animation)
 */
section header {
    justify-content: space-between;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    max-height: 50px;
}
section header .tabs > div {
    background: rgb(225, 224, 224);
    padding: calc(4px + 0.4vw);
    display: inline-block;
    z-index: 100;
    position: relative;
    color: #999;
    margin-right: 10px;
    font-size: calc(0.9em + 0.2vw);
    font-weight: normal;
    vertical-align: top;
}
section header .tabs > div:not(:only-of-type) {
    cursor: pointer;
}
section header .tabs > div.active {
    background: white;
}
