/* BASICS ************************************************************************/
:root {
    /*========== Colors ==========*/
    --first-color: hsl(174, 63%, 40%);      /* #27a699; */
    --first-color-alt: hsl(174, 63%, 36%);
    --title-color: hsl(174, 12%, 15%);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body, #root, .App {
    overflow: hidden;
    scroll-behavior: smooth;
    min-height: 100vh;
    position: relative;
    font-family: 'Open Sans', sans-serif;    /* Arial, Sans-Serif */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1em;
}

html {  /* IMPORTANT to enable reloading by swipping down */
    overflow-y: auto;
}

a {
    text-decoration: none;
    color: var(--first-color);
}


main a:hover {
    text-decoration: underline;
}

ul {
    list-style: none;
}

/*=============== GOOGLE FONTS ===============
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
*/


/* APP ************************************************************************/
section {
    margin: calc(20px + 0.2vh) 1%;
    position: relative;
}

section > div {
    background: white;
    padding: 1%;
    display: block;     
    overflow: hidden;
}

.credits {
    background: none;
    padding: 12px;
    font-size: 12pt;
    height: 40px;
    text-align: center;
    justify-content: center;
    text-align: center;
    color: #aaa;
}

.creditsStyles a {
    color: #aaa;
}




/* ELEMENTS ************************************************************************/
.noStocks .hideIfNoStock {
    display: none;
}

.loading {
    height: 100%;
}
section div.loading {
    min-height: 400px;
}
.loading.failure {
    color: red; 
}
@keyframes spinner {
    to {transform: rotate(360deg);}
}
.loading:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    margin: 100000px;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 50%;
    border-top: 4px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}
.loading > * {
    opacity: 40%;
    z-index: -1 !important;
}



.pnlTable th {
    text-align: left;
}
.pnlTable td {
    text-align: right;
}
.pnlTable td.price {
    color: gray;
}
.pnlTable td.diff {
    text-align: left;
}
.pnlTable td.total {
    border-top: 1px solid #333;
    font-weight:bold;
}


/* DARK SCHEME ************************************************************************/
@media (prefers-color-scheme: dark) {
    .App.darkModeDetectionEnabled {
          background: black !important; 
          color: #ddd;
    }
    
    .darkModeDetectionEnabled a {
        color: white;
    }
}

main {
    background: #eee;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 45px;
    bottom: 0px;
    width: 100%;
}


/* source: https://codepen.io/alvarotrigo/pen/PoKMyNO */
.animate-charcter {
    background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #591272 29%,
        #ff1361 67%,
        #e9e200 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 20s alternate infinite;
    display: inline-block;
    /* font-weight: bold; */
    background-position: top left;
}

@keyframes textclip {
    to {
        background-position: top  right;
    }
}